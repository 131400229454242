import React from "react";
import MenuComponnent from "../components/Menu"
import DemoLiquid from "../components/Chart"

function Dashboard() {

  return (
    <>
    <MenuComponnent content={DashboardContent()} menuKey={"1"}></MenuComponnent>
      {/* <h2>Dashboard</h2> */}
    </>
  );
}

function DashboardContent() {
  return (
    <>
      <h2>Dashboard</h2>
      <div style={{ paddingTop: '10%' }}>
        <DemoLiquid></DemoLiquid>
      </div>
    </>
  )
}

export default Dashboard;
