import React, { useEffect, useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import MuiAlert from '@material-ui/lab/Alert';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from "react-router-dom";
import { Configuration, V0alpha1Api } from '@ory/kratos-client';

function Copyright() {
    return (
      <Typography variant="body2" color="textSecondary" align="center">
        {'Copyright © '}
        <Link color="inherit" href="https://deliverect.com/">
            Deliverect
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
    );
}

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
    paper: {
      marginTop: theme.spacing(8),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(3),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
}));

function Register() {
    const classes = useStyles();
    const history = useHistory();
    
    const kratos = new V0alpha1Api(new Configuration({ basePath: process.env.REACT_APP_KRATOS_PUBLIC_URL }));

    const [flow, setFlow] = useState();
    const [flowId, setFlowId] = useState();
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [csrfToken, setCsrfToken] = useState();
    const [errorMassage, setErrorMassage] = useState();

    useEffect(() => {

        kratos
            .initializeSelfServiceRegistrationFlowForBrowsers({withCredentials: true})
            .then(({ data }) => {
                console.log("DATA=" + data)
                setFlow(data);
                setFlowId(data.id)
                setCsrfToken(data.ui.nodes[0].attributes.value)
            })
            .catch((err) => {
                switch (err.response?.status) {
                    case 400:
                        window.location.href = process.env.REACT_APP_URL + "/dashboard"
                        return    
                }
                throw err
            })

        if (flowId) {
            kratos
                .getSelfServiceRegistrationFlow(flowId, csrfToken, {withCredentials: true})
                .then(({ data }) => {
                    setFlow(data)
                    setCsrfToken(data.ui.nodes[0].attributes.value)
                })
                .catch((err) => {
                    switch (err.response?.status) {
                        case 410:
                        case 403:
                            window.location.href = process.env.REACT_APP_URL + "/login"
                            return;
                        case 400:
                            window.location.href = process.env.REACT_APP_URL + "/dashboard"
                            return
                    }
                    throw err
                })
            return
        }

    }, [])

    const onSubmit = () => {
        let body = {
            "csrf_token": csrfToken,
            "method": "password",
            "password": password || "",
            "traits": {
                "email": username || "",
                "name": {
                    "first": firstName || "",
                    "last": lastName || ""
                }
            }
        }
        kratos
            .submitSelfServiceRegistrationFlow(flowId, body, {withCredentials: true})
            .then(({ data })=> {
                console.log('This is the user session: ', data, data.identity)
                window.location.href = process.env.REACT_APP_URL + "/dashboard"
                return
            })
            .catch((err) => {
                switch (err.response?.status) {
                    case 400:
                        if (firstName.length <= 0) {
                            setErrorMassage("First name can't be empty.")
                        } else if (lastName.length <= 0) {
                            setErrorMassage("Last name can't be empty.")
                        } else if (err.response?.data.error) {
                            setErrorMassage(err.response?.data.error.reason)
                            // return process.env.REACT_APP_URL + "/login";
                        } else if (err.response?.data.ui.nodes[1].messages.length !== 0) {
                            setErrorMassage(err.response?.data.ui.nodes[1].messages[0].text)
                        } else if (err.response?.data.ui.nodes[2].messages.length !== 0) {
                            setErrorMassage(err.response?.data.ui.nodes[2].messages[0].text)
                        } else {
                            setErrorMassage(err.response?.data.ui.nodes[2].messages[0].text)
                        }
                        setTimeout(() => setErrorMassage(undefined), 3000)
                        return
                    case 403:
                        window.location.href = process.env.REACT_APP_URL + "/login"
                        return
                }
                throw err
            })
    }

    return (
        <>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <div className={classes.paper}>
                    <Avatar className={classes.avatar}>
                    <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                    Sign up
                    </Typography>
                    <div className={classes.form}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                        <TextField
                            autoComplete="fname"
                            name="firstName"
                            variant="outlined"
                            required
                            fullWidth
                            id="firstName"
                            label="First Name"
                            autoFocus
                            value={firstName}
                            onChange={(event) => {
                                setFirstName(event.target.value)
                            }}
                        />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                        <TextField
                            variant="outlined"
                            required
                            fullWidth
                            id="lastName"
                            label="Last Name"
                            name="lastName"
                            autoComplete="lname"
                            value={lastName}
                            onChange={(event) => {
                                setLastName(event.target.value)
                            }}
                        />
                        </Grid>
                        <Grid item xs={12}>
                        <TextField
                            variant="outlined"
                            required
                            fullWidth
                            id="email"
                            label="Email Address"
                            name="email"
                            autoComplete="email"
                            value={username}
                            onChange={(event) => {
                                setUsername(event.target.value)
                            }}
                        />
                        </Grid>
                        <Grid item xs={12}>
                        <TextField
                            variant="outlined"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                            value={password}
                            onChange={(event) => {
                                setPassword(event.target.value)
                            }}
                        />
                        </Grid>
                        <Grid item xs={12}></Grid>
                    </Grid>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        onClick={onSubmit}
                    >
                        Sign Up
                    </Button>
                    <Grid container justifyContent="flex-end">
                        <Grid item>
                        <Link href="/login" variant="body2">
                            Already have an account? Sign in
                        </Link>
                        </Grid>
                    </Grid>
                    </div>
                </div>
                {errorMassage
                    ? (<>
                        <Alert severity="error" style={{margin: "auto", width: "75%", padding: "10px", marginTop: "5%"}}>
                            {errorMassage}
                        </Alert>
                    </>)
                    : (<>
                        </>)
                }
                <Box mt={5}>
                    <Copyright />
                </Box>
            </Container>
            
        </>
    );
}

export default Register;
