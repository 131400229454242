import React from "react";

function Settings() {
  return (
    <>
      <h2>Settings</h2>
    </>
  );
}

export default Settings;
