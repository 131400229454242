import React, { useEffect, useState } from 'react';
import { Configuration as HydraConfiguration, AdminApi as HydraAdminApi, PublicApi } from '@ory/hydra-client';

function Consent() {
  
  const hydra = new HydraAdminApi(new HydraConfiguration({ basePath: process.env.REACT_APP_HYDRA_ADMIN_URL }));
  // const hydraPublic = new PublicApi(new HydraConfiguration({ basePath: process.env.REACT_APP_HYDRA_PUBLIC_URL }));

  const consentChallenge = (new URL(document.location)).searchParams.get("consent_challenge");
  const [hydraConsentScopes, setHydraConsentScopes] = useState();

  useEffect(() => {

    hydra
      .getConsentRequest(consentChallenge)
      .then(({data: body}) => {
        setHydraConsentScopes(body.requested_scope)
        if (body.skip) {
          return hydra
            .acceptConsentRequest(consentChallenge, {
              grant_scope: body.requested_scope,
              grant_access_token_audience: body.requested_access_token_audience,
              session: {}
            })
            .then(({data : acceptBody}) => {
              window.location.href = String(acceptBody.redirect_to)
            })
        }
      
      })

  }, [])

  const onConsentSubmit = () => {
    hydra
      .acceptConsentRequest(consentChallenge, {
        grant_scope: hydraConsentScopes
      })
      .then(({data : body}) => {
        window.location.href = String(body.redirect_to)
      })
  }

  return (
    <>
      <h2>Consent</h2>
      <p>Yes, we all know that you will approve without reading the legal document.</p>
      <p>(That's why there is no legal document)</p>
      <button onClick={onConsentSubmit}>Continue</button>
    </>
  );
}

export default Consent;
