import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

import Dashboard from "./pages/Dashboard"
import Consent from "./pages/Consent"
import Callback from "./pages/Callback"
import Profile from "./pages/Profile"
import Login from "./pages/Login"
import Register from "./pages/Register.js"
import Settings from "./pages/Settings"
import Error from "./pages/Error"
import Recovery from "./pages/Recovery"

function App() {
  return (
    <>
      <Router>
        <Switch>
          <Route exact path="/dashboard">
            <Dashboard />
          </Route>
          <Route exact path="/consent">
            <Consent />
          </Route>
          <Route exact path="/callback">
            <Callback />
          </Route>
          <Route exact path="/profile">
            <Profile />
          </Route>
          <Route exact path="/settings">
            <Settings />
          </Route>
          <Route exact path="/register">
            <Register />
          </Route>
          <Route exact path="/login">
            <Login />
          </Route>
          <Route exact path="/recovery">
            <Recovery />
          </Route>
          <Route exact path="/">
            <Login />
          </Route>
          <Route>
            <Error />
          </Route>
        </Switch>
      </Router>
    </>
  );
}

export default App;
