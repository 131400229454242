import React from "react";

function Error() {
  return (
    <>
      <h2>Error</h2>
    </>
  );
}

export default Error;
