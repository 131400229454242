import React from "react";
import {
  Box,
  Container,
  Grid
} from '@material-ui/core';
import MenuComponnent from "../components/Menu"
import AccountProfile from "../components/AccountProfile"
import AccountProfileDetails from "../components/AccountProfileDetails"

function Profile() {
  return (
    <>
      <MenuComponnent content={ProfileContent()} menuKey={"5"}></MenuComponnent>
    </>
  );
}

function ProfileContent() {
  return (
    <>
      <div style={{ paddingTop: '10%' }}>
        <Box
          sx={{
            backgroundColor: 'background.default',
            minHeight: '100%',
            py: 3
          }}
        >
          <Container maxWidth="lg">
            <Grid
              container
              spacing={3}
            >
              <Grid
                item
                lg={4}
                md={6}
                xs={12}
              >
                <AccountProfile />
              </Grid>
              <Grid
                item
                lg={8}
                md={6}
                xs={12}
              >
                <AccountProfileDetails />
              </Grid>
            </Grid>
          </Container>
        </Box>
      </div>
    </>
  )
}

export default Profile;
