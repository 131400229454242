const clientOAuth2 = require('client-oauth2')

export const options = {
  clientId: 'client-deliverect-local-2',
  accessTokenUri: process.env.REACT_APP_HYDRA_PUBLIC_URL + '/oauth2/token',
  authorizationUri: process.env.REACT_APP_HYDRA_PUBLIC_URL + '/oauth2/auth',
  redirectUri: process.env.REACT_APP_URL + '/callback',
  scopes: ['openid', 'offline', 'offline_access', 'profile']
}

export const oauth2Client = new clientOAuth2(options)

export const generateRandomString = () => {
  var array = new Uint32Array(28);
  window.crypto.getRandomValues(array);
  return Array.from(array, dec => ('0' + dec.toString(16)).substr(-2)).join('');
}

export const generateStateAndSave = () => {
  const state = generateRandomString()
  localStorage.setItem('ephemeral_state', state)
  return state
}

export const getSavedState = () => {
  return localStorage.getItem('ephemeral_state')
}

// export const saveAccessToken = (token) => {
//   localStorage.setItem('access_token', token)
// }

// export const getSavedAccessToken = () => localStorage.getItem('access_token')

// export const saveRefreshToken = (token) => {
//   localStorage.setItem('refresh_token', token)
// }

// export const getSavedRefreshToken = () => localStorage.getItem('refresh_token')

// export const saveIdToken = (token) => {
//   localStorage.setItem('id_token', token)
// }

// export const getSavedIdToken = () => localStorage.getItem('id_token')
