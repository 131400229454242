import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import 'antd/dist/antd.css';
import { Menu, Layout, Image, Alert, Avatar, Badge, Dropdown, Skeleton } from 'antd';
import {
  AppstoreOutlined,
  LogoutOutlined,
  SettingOutlined,
  AimOutlined,
  HomeOutlined,
  AreaChartOutlined,
  UserOutlined,
  QuestionCircleFilled,
  BellOutlined,
} from '@ant-design/icons';
import Cookies from 'universal-cookie';
import { Configuration, V0alpha1Api } from '@ory/kratos-client'

import DemoLiquid from "./Chart"

const { Header, Content, Footer, Sider } = Layout;
// const { SubMenu } = Menu;


const MenuComponent = ({content, menuKey}) => {
    const cookies = new Cookies();
    const history = useHistory();

    const kratos = new V0alpha1Api(new Configuration({ basePath: process.env.REACT_APP_KRATOS_PUBLIC_URL }));
    
    const [collapsed, setCollapsed] = React.useState(false);
    const [logoutToken, setLogoutToken] = useState();
    const [selectedMenuItem, setSelectedMenuItem] = useState(menuKey);

    const onCollapse = () => {
        if (collapsed) {
            setCollapsed(false)
        } else {
            setCollapsed(true)
        }
    };

    useEffect(() => {
        fetch(process.env.REACT_APP_KRATOS_PUBLIC_URL + "/sessions/whoami", {withCredentials: true, credentials: 'include'})
            .then(({ data }) => {
                console.log(data)
            })

        kratos
            .createSelfServiceLogoutFlowUrlForBrowsers(undefined, {credentials: 'include', withCredentials: true})
            .then(({ data }) => {
                const lo = new URL(String(data.logout_url))
                setLogoutToken(String(lo.searchParams.get('token')))
            })
            .catch((err) => {
                switch (err.response?.status) {
                    case 401:
                        return
                }
                return Promise.reject(err)
            })
    }, [])

    const onLogout = () => {
        if (logoutToken) {
            kratos.submitSelfServiceLogoutFlow(logoutToken, {credentials: 'include', withCredentials: true})
            .then(() => {
                window.location.href = process.env.REACT_APP_URL + "/login"
                return;
            })
            .catch((err) => {
                switch (err.response?.status) {
                    case 401:
                        // Token related error
                        return
                }
                throw err
            })
        }
    }

    return (
        <>
            <Layout style={{ minHeight: '100vh' }}>
                <Sider theme="light" collapsible collapsed={collapsed} onCollapse={onCollapse} style={{ padding: 0}}>
                    <Menu theme="ligth" defaultSelectedKeys={[selectedMenuItem]} selectedKeys={[selectedMenuItem]} onClick={(event) => {
                        setSelectedMenuItem(event.key)
                    }} mode="inline" style={{height: window.innerHeight}}>
                        {
                            collapsed
                            ? <Image width={35} src="deliverect-logo-small.png" preview={false} style={{margin:20}}/>
                            : <>
                                <Image width={150} src="deliverect-logo.png" preview={false} style={{margin:20}}/>
                                <Alert message="ORY demo" type="success" closable style={{width:160, borderRadius:8, margin:20, borderColor:'white', color:'white', textAlign:'center', height:50}}/>
                            </>
                        }
                        
                        
                        <Menu.Item key="1" icon={<HomeOutlined />} onClick={()=>{
                            history.push("/dashboard")
                                        // window.location.href = process.env.REACT_APP_URL + "/profile"
                        }}>
                            Dashboard
                        </Menu.Item>
                        <Menu.Item key="2" icon={<AimOutlined />} >
                            Locations
                        </Menu.Item>
                        <Menu.Item key="3" icon={<AppstoreOutlined />}>
                            Partners
                        </Menu.Item>
                        <Menu.Item key="4" icon={<AreaChartOutlined />}>
                            Analysis
                        </Menu.Item>
                        <Menu.Item key="5" icon={<UserOutlined />} onClick={()=>{
                            // setSelectedMenuItem(event.key)
                            history.push("/profile")
                            // setSelectedMenuItem("5")
                                        // window.location.href = process.env.REACT_APP_URL + "/profile"
                        }}>
                        Profile
                        </Menu.Item>
                        {/* <SubMenu key="sub1" icon={<UserOutlined />} title="Profile">
                        <Menu.Item key="5">Tom</Menu.Item>
                        <Menu.Item key="6">Bill</Menu.Item>
                        <Menu.Item key="7">Alex</Menu.Item>
                        </SubMenu>
                        <SubMenu key="sub2" icon={<TeamOutlined />} title="Team">
                        <Menu.Item key="8">Team 1</Menu.Item>
                        <Menu.Item key="9">Team 2</Menu.Item>
                        </SubMenu> */}
                        <Menu.Item key="6" icon={<SettingOutlined />}>
                            Settings
                        </Menu.Item>

                        <Menu.Item onClick={onLogout} key="7" icon={<LogoutOutlined /> } danger>
                            Logout
                        </Menu.Item>

                    </Menu>
                </Sider>
                <Layout className="site-layout" style={{ backgroundColor:'white' }}>
                    <Header className="site-layout-background" style={{ padding: 0, backgroundColor:'white'}}>
                        {/* <SearchOutlined /> */}
                        <div style={{ display:"inline-block", float:"right" }}>
                            {/* <SettingOutlined style={{ fontSize:16 }}/> */}
                            <Badge size="small" count={9} style={{ fontSize:9 }} >
                                <BellOutlined style={{ marginLeft: 10, fontSize:16 }}/>
                            </Badge>
                            <QuestionCircleFilled style={{ marginLeft: 15, fontSize:16 }}/>

                            <Dropdown overlay={
                                <Menu style={{ width:100 }}>
                                    <Menu.Item key="0" onClick={()=>{
                                        window.location.href = process.env.REACT_APP_URL + "/profile"
                                    }}>
                                        <UserOutlined /> Profile
                                    </Menu.Item>
                                    <Menu.Item key="1">
                                        <SettingOutlined/> Settings
                                    </Menu.Item>
                                    <Menu.Divider />
                                    <Menu.Item key="3" danger onClick={onLogout}>
                                        <LogoutOutlined /> Logout
                                    </Menu.Item>
                                </Menu>
                            }>
                                <Avatar shape="circle" size="10" style={{display:"inline-block", marginLeft: 10, marginRight: 30, color:"black"}} >A</Avatar>
                            </Dropdown>
                        </div>

                    </Header>
                    <Content style={{ margin: '0 16px' }}>
                        {/* <Breadcrumb style={{ margin: '16px 0' }}>
                        <Breadcrumb.Item>User</Breadcrumb.Item>
                        <Breadcrumb.Item>Bill</Breadcrumb.Item>
                        </Breadcrumb>
                        <div className="site-layout-background" style={{ padding: 24, minHeight: 360, backgroundColor:'white', borderRadius:10 }}>
                        Bill is a cat.
                        </div> */}
                        {/* <div style={{ paddingTop: '10%' }}>
                            <DemoLiquid></DemoLiquid>
                        </div> */}
                        {content}
                    </Content>
                    <Footer style={{ textAlign: 'center', height:60, fontSize:12 }}>Deliverect ©2021</Footer>
                </Layout>
            </Layout>
            
        </>
    );
};

export default MenuComponent;

// ReactDOM.render(<MenuComponent />, document.getElementById('container'));
