import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom'

import { oauth2Client, options } from '../api/oauth2'
import { getSavedPKCEVerifier } from '../api/pkce'

function Callback() {

  const [jwt, setJwt] = useState([]);
  const search = useLocation().search

  useEffect(() => {
    const verifier = getSavedPKCEVerifier()
    const code = new URLSearchParams(search).get('code')

    const data = {
      client_id: options.clientId,
      redirect_uri: options.redirectUri,
      code: code,
      code_verifier: verifier,
      grant_type: 'authorization_code',
      // grant_type: ['authorization_code', "refresh_token"],
    }

    oauth2Client.code
    .getToken(window.location.href, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: data,
    })
    .then(({ data: body }) => {
      setJwt(JSON.stringify(body))
      setTimeout(() => setJwt(""), 3000)
      window.location.href = process.env.REACT_APP_URL + "/dashboard"
    })
    .catch((err) => {
      console.log(err)
      throw err
    })

  }, [])

  return (
    <>
      <h2>Callback</h2>
      <p></p>
      <h2>{jwt}</h2>
    </>
  );
}

export default Callback;
