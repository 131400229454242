import React, { useEffect, useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import MuiAlert from '@material-ui/lab/Alert';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { Configuration as KratosConfiguration, V0alpha1Api } from '@ory/kratos-client';
import { Configuration as HydraConfiguration, AdminApi as HydraAdminApi } from '@ory/hydra-client';
import { generateStateAndSave, oauth2Client } from '../api/oauth2'
import { generateVerifierChallengeSetAndSave } from '../api/pkce'

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://deliverect.com/">
                Deliverect
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
    root: {
      height: '100vh',
    },
    image: {
      backgroundImage: 'url(https://source.unsplash.com/random)',
      backgroundRepeat: 'no-repeat',
      backgroundColor:
        theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
      backgroundSize: 'cover',
      backgroundPosition: 'center',
    },
    paper: {
      margin: theme.spacing(8, 4),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    csrf: {
        visibility: 'hidden'
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
}));

function Login() {
    const classes = useStyles();
    
    const kratos = new V0alpha1Api(new KratosConfiguration({ basePath: process.env.REACT_APP_KRATOS_PUBLIC_URL }));
    const hydra = new HydraAdminApi(new HydraConfiguration({ basePath: process.env.REACT_APP_HYDRA_ADMIN_URL }));

    
    const [flow, setFlow] = useState();
    const [flowId, setFlowId] = useState();
    const [hydraChallenge, setHydraChallenge] = useState("");
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [csrfToken, setCsrfToken] = useState();
    const [errorMassage, setErrorMassage] = useState();
    
    useEffect( async () => {

        if (!(new URL(document.location)).searchParams.get("login_challenge")) {
            const state = generateStateAndSave()
            var code_challenge = await generateVerifierChallengeSetAndSave()

            setTimeout(() => {
                window.location = oauth2Client.code.getUri({
                  query: {
                    state: state,
                    code_challenge: code_challenge,
                    code_challenge_method: 'S256',
                    // audience: process.env.REACT_APP_URL,
                  },
                })
              }, 0)

            setHydraChallenge(new URL(document.location)).searchParams.get("login_challenge");
        }

        const loginChallenge = (new URL(document.location)).searchParams.get("login_challenge");
        setHydraChallenge(loginChallenge);

        // Kratos below
        kratos
            .initializeSelfServiceLoginFlowForBrowsers(true, {withCredentials: true})
            .then(({ data }) => {
                setFlow(data);
                setFlowId(data.id)
                setCsrfToken(data.ui.nodes[0].attributes.value)
            })
            .catch((err) => {
                switch (err.response?.status) {
                    case 400:
                        window.location.href = process.env.REACT_APP_URL + "/dashboard"
                        return;
                }
                throw err
            })

        if (flowId) {
            kratos
                .getSelfServiceLoginFlow(flowId, csrfToken, {withCredentials: true})
                .then(({ data: body }) => {
                    setFlow(body)
                    setCsrfToken(body.ui.nodes[0].attributes.value)
                })
                .catch((err) => {
                    switch (err.response?.status) {
                        case 410:
                        case 403:
                            window.location.href = process.env.REACT_APP_URL + "/login"
                            return;
                        case 400:
                            window.location.href = process.env.REACT_APP_URL + "/dashboard"
                            return;
                    }
                    throw err
                })
        }
        
    }, [])

    const onSubmit = () => {

        hydra
            .getLoginRequest(hydraChallenge)
            .then(({ data: body }) => {

                // If hydra was already able to authenticate the user, skip will be true and we do not need to re-authenticate
                if (body.skip) {
                    return hydra
                        .acceptLoginRequest(hydraChallenge, {
                            subject: body.subject,
                            remember: Boolean(body.remember),
                            remember_for: 3600
                        })
                        .then(({ data: acceptBody }) => {
                            window.location.href = String(acceptBody.redirect_to)
                        })

                } 
            })
            .catch((err) => {
                console.log(err)
                throw err
            })

        console.log(csrfToken)

        let requestBody = {
            "csrf_token": csrfToken,
            "method": "password",
            "password": password || "",
            "password_identifier": username || ""
        }
        
        kratos
            .submitSelfServiceLoginFlow(flowId, requestBody, {withCredentials: true})
            .then(()=> {
                hydra
                    .acceptLoginRequest(hydraChallenge, {
                        subject: requestBody.password_identifier,
                        remember: true,
                        remember_for: 3600
                    })
                    .then(({data: body}) => {
                        window.location.href = String(body.redirect_to)
                    })
                    .catch((err) => {
                        console.log(err)
                        throw err
                    })
            })
            .catch((err) => {
                switch (err.response?.status) {
                    case 400:
                        if (err.response?.data.ui.nodes[1].messages.length !== 0) {
                            setErrorMassage(err.response?.data.ui.nodes[1].messages[0].text)
                        } else if (err.response?.data.ui.nodes[2].messages.length !== 0) {
                            setErrorMassage(err.response?.data.ui.nodes[2].messages[0].text)
                        } else {
                            setErrorMassage(err.response?.data.ui.messages[0].text)
                        }
                        setTimeout(() => setErrorMassage(""), 3000)
                    case 403:
                        // window.location.href = process.env.REACT_APP_URL + "/login"
                        return;
                }
                throw err
            })

    }

    return (
        <>
            <Grid container component="main" className={classes.root}>
                <CssBaseline />
                <Grid item xs={false} sm={4} md={7} className={classes.image} />
                <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
                    <div className={classes.paper}>
                    <Avatar className={classes.avatar}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Sign in
                    </Typography>
                    <div className={classes.form}>
                        <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="email"
                        autoComplete="email"
                        value={username}
                        onChange={(event) => {
                            setUsername(event.target.value)
                        }}
                        autoFocus
                        />
                        <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        value={password}
                        onChange={(event) => {
                            setPassword(event.target.value)
                        }}
                        autoComplete="current-password"
                        />
                        <FormControlLabel
                        control={<Checkbox value="remember" color="primary" />}
                        label="Remember me"
                        />
                        <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        onClick={onSubmit}
                        >
                        Sign In
                        </Button>
                        <Grid container>
                        <Grid item xs>
                            <Link href="/recovery" variant="body2">
                            Forgot password?
                            </Link>
                        </Grid>
                        <Grid item>
                            <Link href="/register" variant="body2">
                            {"Don't have an account? Sign Up"}
                            </Link>
                        </Grid>
                        </Grid>
                        <Box mt={5}>
                        <Copyright />
                        </Box>
                    </div>
                    </div>
                    {errorMassage
                    ? (<>
                        <Alert severity="error" style={{margin: "auto", width: "75%"}}>
                            {errorMassage}
                        </Alert>
                    </>)
                    : (<>
                        </>)
                    }
                </Grid>
            </Grid>
        </>
    );
}

export default Login;
